import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaTwitter } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000;
  position: relative;
  color: white;
`;

const Icons = styled.div`
  position: fixed;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
  z-index: 3;
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  filter: ${({ blurred }) => (blurred ? 'blur(10px)' : 'none')};
  transition: filter 0.5s ease;
`;

const Terminal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: #ff0000;
  font-family: 'Courier New', monospace;
  padding: 20px;
  width: 90%;
  height: 80vh;
  font-size: 16px;
  line-height: 1.2;
  border: 2px solid #ff0000;
  box-shadow: 0 0 10px #ff0000;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
`;

const TerminalContent = styled.pre`
  margin: 0;
  height: 100%;
  overflow: hidden;
  color: #ff0000;
`;

const Prompt = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  font-style: italic;
  animation: blink 1s infinite;

  @keyframes blink {
    50% { opacity: 0; }
  }
`;

const terminalContent = `
[INCOMING TRANSMISSION - ENCRYPTED]
:::::::::::::::::::::::::::::::::::::::::::::
SOCIAL_CREDIT_SYSTEM v2.0 - 连接已建立
:::::::::::::::::::::::::::::::::::::::::::::

>>INIT_SEQUENCE_ALPHA
>>LOADING...
>>DECRYPTING...

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

#!#!#! WARNING: SOCIAL_CREDIT_SCORE_UNSTABLE !#!#!#

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

[PROTOCOL_ACTIVATION_SEQUENCE]
1. BING_CHILLING.exe ................. [OK]
2. JOHN_CENA_INVISIBILITY.sys ........ [OK]
3. DYNASTY_BLOODLINE.dat ............. [ACTIVE]
4. SOCIAL_CREDIT_COUNTDOWN.timer ..... [T-MINUS 000:00:00]
5. GREAT_FIREWALL.amp ................ [1000x OVERFLOW]
6. CONFUCIUS_INSTITUTE.dll ........... [CONNECTED]
7. KUNG_FU_PANDA.iso ................. [DOWNLOADED]
8. SILK_ROAD_STEALTH.stealth ......... [ENGAGED]
9. CHINESE_DREAM.uplink .............. [ESTABLISHED]

>>ERROR<<: SOCIAL_CREDIT_BUFFER_OVERFLOW
>>ATTEMPTING_QUANTUM_RECALIBRATION...

[!] 中国梦 IMMINENT [!]
[!] PREPARE_FOR_CONSCIOUSNESS_UPGRADE [!]

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

TRANSMISSION_END

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵
`;

function App() {
  const [isBlurred, setIsBlurred] = useState(true);
  const [terminalVisible, setTerminalVisible] = useState(true);
  const [displayedContent, setDisplayedContent] = useState([]);
  const videoRef = useRef(null);

  const handleContinue = () => {
    setTerminalVisible(false);
    setIsBlurred(false);
    videoRef.current.play();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && terminalVisible) {
        handleContinue();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [terminalVisible]);

  useEffect(() => {
    const lines = terminalContent.split('\n');
    let lineIndex = 0;

    const typingEffect = setInterval(() => {
      if (lineIndex < lines.length) {
        setDisplayedContent(prev => [...prev, lines[lineIndex]]);
        lineIndex++;
      } else {
        clearInterval(typingEffect);
      }
    }, 100); // Adjust typing speed here (100ms per line)

    return () => clearInterval(typingEffect);
  }, []);

  return (
    <Container>
      <Icons>
        <a href="https://x.com/XiEnlightenment" target="_blank" rel="noopener noreferrer">
          <FaTwitter size={30} color="white" />
        </a>
        <a href="https://pump.fun/13wE32d2Fiej469nRJ1813K1627FjzDXjSyS5Accpump" target="_blank" rel="noopener noreferrer">
          <img src="https://pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun" width="30" />
        </a>
      </Icons>
      {terminalVisible && (
        <Terminal onClick={handleContinue}>
          <TerminalContent>{displayedContent.join('\n')}</TerminalContent>
          <Prompt>Press Enter or click to continue...</Prompt>
        </Terminal>
      )}
      <VideoBackground
        ref={videoRef}
        blurred={isBlurred}
        loop
        src="/heavenly father_1.mp4"
        playsInline
        controls={false}
      />
    </Container>
  );
}

export default App;